import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import { Clamp } from '../../modules/Clamp.js';
import { UpdateJobPermissions } from '../../modules/UpdateJobPermissions.js';
import '../katapult-elements/katapult-button.js';

class AppTimelineEditor extends PolymerElement {
  static get template() {
    return html`
      <style>
        :host {
          width: 100%;
        }
      </style>
      <template is="dom-if" if="[[models]]" restamp="">
        <bind-path base="[[models]]" path="[[selectedModelKey]].models" value="{{selectedModels}}"></bind-path>
        <bind-path base="[[models]]" path="[[selectedModelKey]]" value="{{selectedTimeline}}"></bind-path>
        <div style$="display: flex; align-items: center; margin-left: [[indentPixels]]px;">
          <template is="dom-if" if="[[indent]]">
            <iron-icon style="color: var(--primary-text-color-faded);" icon="subdirectory-arrow-right"></iron-icon>
          </template>
          <katapult-drop-down
            items="[[models]]"
            sort="[[modelsSortFunction]]"
            filter="[[modelsFilterFunction]]"
            value="{{selectedModelKey}}"
            value-path="$key"
            label-path="name"
            label="Timeline Model"
            no-label-float=""
          ></katapult-drop-down>
        </div>
        <template is="dom-if" if="[[selectedModels]]" restamp="">
          <app-timeline-editor
            id="subEditor"
            job-id="[[jobId]]"
            user-group="[[userGroup]]"
            models="[[selectedModels]]"
            indent-count="[[add(indentCount, 1)]]"
            show-action-buttons="[[showActionButtons]]"
          ></app-timeline-editor>
        </template>
        <template is="dom-if" if="[[and(showActionButtons, selectedTimeline.stages)]]" restamp="">
          <div style="width: 100%; text-align: center;">
            <katapult-button color="var(--paper-gray-500)" on-click="loadTimeline">Set Timeline</katapult-button>
          </div>
        </template>
        <template is="dom-if" if="[[and(showActionButtons, selectedTimeline.remove)]]" restamp="">
          <div style="width: 100%; text-align: center;">
            <katapult-button color="var(--paper-red-500)" on-click="removeTimeline">Remove Timeline</katapult-button>
          </div>
        </template>
      </template>
    `;
  }

  static get is() {
    return 'app-timeline-editor';
  }
  static get properties() {
    return {
      indent: {
        type: Boolean,
        value: false
      },
      indentCount: {
        type: Number,
        value: 0
      },
      indentPixels: {
        type: Number,
        value: 0
      },
      jobId: {
        type: String
      },
      models: {
        type: Object
      },
      showActionButtons: {
        type: Boolean,
        value: false
      }
    };
  }
  static get observers() {
    return ['updateIndent(indentCount)'];
  }
  ready() {
    super.ready();
  }
  add(x, y) {
    return x + y;
  }
  and(a, b) {
    return a && b;
  }
  loadTimeline() {
    if (this.get('selectedTimeline.stages') && this.jobId) {
      let temp = {};
      for (let key in this.selectedTimeline) {
        if (!key.startsWith('$')) temp[key] = this.selectedTimeline[key];
      }
      let update = { 'metadata/timeline': temp };
      FirebaseWorker.ref(`photoheight/jobs/${this.jobId}`).update(update);
      UpdateJobPermissions(this.jobId, this.userGroup, update, {
        fetchSharedCompanies: true
      });
    }
  }
  modelsFilterFunction(x) {
    return x && !x.hidden;
  }
  modelsSortFunction(a, b) {
    let aPriority = (a || {}).priority || Infinity;
    let bPriority = (b || {}).priority || Infinity;
    return aPriority - bPriority;
  }
  removeTimeline() {
    let update = { 'metadata/timeline': null };
    FirebaseWorker.ref(`photoheight/jobs/${this.jobId}`).update(update);
    UpdateJobPermissions(this.jobId, this.userGroup, update, {
      fetchSharedCompanies: true
    });
  }
  or(x, y) {
    return x || y;
  }
  updateIndent() {
    this.indent = this.indentCount > 0;
    this.indentPixels = Clamp((this.indentCount - 1) * 24, 0);
  }
}
window.customElements.define(AppTimelineEditor.is, AppTimelineEditor);
